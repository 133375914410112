import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import backgroundHero from '../../assets/hero-bg.jpg';
import HeroCard from './HeroCard';

function Hero() {
    return (
        <StyledHero backgroundHero={backgroundHero}>
            <Container>
                <StyledHeroWrapper>
                    <h1>
                        Консультация травматолога <br /> в медицинском центре «XXI Век»
                    </h1>
                    <HeroCard />
                </StyledHeroWrapper>
            </Container>
        </StyledHero>
    );
}

const StyledHeroWrapper = styled.div`
    padding: 50px 0;
    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 0;
    }
    h1 {
        font-weight: 800;
        font-size: 34px;
        line-height: 120%;
        color: var(--color-white);
        max-width: 540px;
        margin-bottom: 40px;
        @media (max-width: 992px) {
            text-align: center;
            font-size: 20px;
            margin-bottom: 16px;
        }
    }
`;

const StyledHero = styled.section`
    height: calc(100vh - 114px);
    width: 100%;
    background: url(${backgroundHero}) no-repeat center center / cover;
    box-shadow: var(--shadow);
    @media (max-width: 992px) {
        height: calc(100vh - 72px);
        background-position: right 19% bottom;
    }
`;

export default Hero;
