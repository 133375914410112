import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import DoctorsCard from './DoctorsCard';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import videoava1 from '../../assets/video/selin.mp4';
import videoava2 from '../../assets/video/moskaleno.mp4';
import videoava3 from '../../assets/video/shulepov.mp4';
import videoava4 from '../../assets/video/gvozdev.mp4';

const doctorsInfo = [
    {
        name: 'Селин Александр Викторович',
        descr: (
            <>
                <p>
                    Имеет большой опыт работы в стационаре, где проводит консервативное и оперативное лечение пациентов травматологического отделения.
                </p>
                <p>Приоритетные направления практической деятельности:</p>
                <ul>
                    <li>Деформирующий артроз коленного и тазобедренного суставов.</li>
                    <li>Травмы коленного сустава.</li>
                    <li>Артроскопия коленного сустава.</li>
                </ul>
            </>
        ),
        rank: 'Стаж 24 года',
        videoAvatar: videoava1,
    },
    {
        name: 'Москаленко Алексей Витальевич',
        descr: (
            <>
                <p>Имеет опыт работы травматологом-ортопедом в детском ортопедическом стационаре им. Г.И.Турнера с 2001 по 2020 года.</p>
                <p>Является ведущим специалистом по артроскопии коленного сустава как у детей так и у взрослых.</p>
            </>
        ),
        rank: 'Стаж 23 года',
        videoAvatar: videoava2,
    },
    {
        name: 'Шулепов Дмитрий Александрович',
        descr: (
            <>
                <p>
                    Опыт работы в крупнейшем травматолого- ортопедическом стационаре страны. Защитил кандидатскую диссертацию по хирургическому
                    лечению повреждений задней крестообразной связки. Многократный участник конференций и симпозиумов.
                </p>
                <p>Выполняет большинство артроскопических операций на крупных суставах (коленный, плечевой, голеностопный, локтевой).</p>
            </>
        ),
        rank: 'Стаж 20 года',
        videoAvatar: videoava3,
    },
];

function Doctors() {
    return (
        <StyledDoctors id="doctors">
            <Container>
                <Title>Наши врачи</Title>
            </Container>
            <StyledDoctorsContainer>
                <StyledSwiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    watchSlidesVisibility={false}
                    watchOverflow={true}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: '3',
                            // centeredSlides: true,
                        },
                    }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {doctorsInfo.map((item) => (
                        <SwiperSlide key={item.name}>
                            <DoctorsCard {...item} />
                        </SwiperSlide>
                    ))}
                </StyledSwiper>
            </StyledDoctorsContainer>
        </StyledDoctors>
    );
}

export const StyledSwiper = styled(Swiper)`
    && {
        padding: 10px 30px 40px;
        margin-left: -15px;
        margin-right: -15px;
        & .swiper-button-prev,
        & .swiper-button-next {
            top: calc(50% - 25px);
            @media (max-width: 992px) {
                &::after {
                    font-size: 28px;
                }
            }
        }
        & .swiper-button-prev {
            left: 0px;
        }
        & .swiper-button-next {
            right: 0px;
        }
        & .swiper-slide {
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
        }
        /* & .swiper-slide {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        & .swiper-slide-active,
        & .swiper-slide-next,
        & .swiper-slide-prev {
            opacity: 1;
        } */
    }
`;

const StyledDoctorsContainer = styled.section`
    position: relative;
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
    @media (max-width: 992px) {
        padding: 0 15px;
    }
`;

const StyledDoctors = styled.section`
    padding-bottom: 64px;
    @media (max-width: 992px) {
        padding-bottom: 40px;
    }
`;

export default Doctors;
