import { useState } from 'react';
import Doctors from './components/Doctors/Doctors';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Requirements from './components/Requirements/Requirements';
import Reviews from './components/Reviews/Reviews';
import Stages from './components/Stages/Stages';
import SubmitApplication from './components/SubmitApplication/SubmitApplication';
import Popup from './components/UI/Popup/Popup';
import WhyChoose from './components/WhyChoose/WhyChoose';
import { PopupValue } from './context';
import { YMInitializer } from 'react-yandex-metrika';

function App() {
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    return (
        <div className="App">
            <YMInitializer accounts={[49402945]} />
            <PopupValue.Provider value={{ open, success, setOpen, setSuccess }}>
                <Header />
                <Hero />
                <Requirements />
                <Doctors />
                <FAQ />
                <SubmitApplication />
                <Stages />
                <WhyChoose />
                <Reviews />
                <Footer />
                {open && <Popup />}
            </PopupValue.Provider>
        </div>
    );
}

export default App;
