import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import Item1 from '../../assets/item1.svg';
import Item2 from '../../assets/item2.svg';
import Item3 from '../../assets/item3.svg';
import Item4 from '../../assets/item4.svg';
import Item5 from '../../assets/item5.svg';
import Item6 from '../../assets/item6.svg';
import a1 from '../../assets/a1.png';
import a2 from '../../assets/a2.png';
import a3 from '../../assets/a3.png';
import a4 from '../../assets/a4.png';
import a5 from '../../assets/a5.png';

function Stages() {
    return (
        <StyledStages>
            <Container>
                <Title>Этапы лечения при проведении артроскопии</Title>
                <StyledStagesWrapper>
                    <StyledStagesItem>
                        <img src={Item1} width="103" height="103" alt="Консультация травматолога" />
                        <h4>Консультация травматолога</h4>
                        <p>
                            Перед операцией происходит серьезная диагностика общего состояния организма, оценка риска вмешательства, выявления скрытых
                            заболеваний других органов и систем. Врач проведет полное обследование вашего состояния здоровья, ознакомится с
                            результатами предыдущих диагностических исследований и либо порекомендует вам артроскопию, либо подберет способ лечения
                            без операции.
                        </p>
                    </StyledStagesItem>
                    <StyledStagesItem>
                        <img src={Item2} width="103" height="103" alt="Обследование перед артроскопией" />
                        <h4>Обследование перед артроскопией</h4>
                        <p>
                            Процедура проводится под проводниковой анестезией (сильным обезболиванием длительного действия) в сочетании с
                            фармакологическим сном. Чтобы убедиться, что у пациента нет противопоказаний к анестезии, обязательно, перед
                            запланированной операцией проводятся клинический и биохимический анализ крови, делается флюорография, ЭКГ и консультация
                            терапевта и анестезиолога.
                        </p>
                    </StyledStagesItem>
                    <StyledStagesItem>
                        <img src={Item3} width="103" height="103" alt="Операция на суставах" />
                        <h4>Операция на суставах</h4>
                        <p>
                            Операция проводится в Центре Амбулаторной хирургии клиники «XXI век» в условиях максимальной безопасности и комфорта
                            пациента с использованием лучшего современного оборудования.
                        </p>
                    </StyledStagesItem>
                    <StyledStagesItem>
                        <img src={Item4} width="103" height="103" alt="Перевязки после операции" />
                        <h4>Перевязки после операции</h4>
                        <p>
                            Первую перевязку через 1-2 дня после операции выполняет врач травматолог в нашей клинике или у вас дома. В среднем
                            требуется 2-3 процедуры.
                        </p>
                    </StyledStagesItem>
                    <StyledStagesItem>
                        <img src={Item5} width="103" height="103" alt="Реабилитация до полного выздоровления" />
                        <h4>Реабилитация до полного выздоровления</h4>
                        <p>
                            Мы сопровождаем наших пациентов до полного восстановления двигательных функций. Реабилитация после артроскопии проводится
                            нашими специалистами как на территории медицинских центров XXI век, так и на дому.
                        </p>
                    </StyledStagesItem>
                    <StyledStagesItem>
                        <img src={Item6} width="103" height="103" alt="Результаты лечения" />
                        <h4>Результаты лечения</h4>
                        <p>
                            При повреждении мениска симптоматика уходит после начала нагрузки (возможно в первый день после операции). При артроскопии
                            по поводу дегенеративного заболевания постепенно (за несколько дней/недель) во время курса восстановительного лечения.
                        </p>
                    </StyledStagesItem>
                </StyledStagesWrapper>
            </Container>
        </StyledStages>
    );
}

const StyledStagesItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    &:first-child::after {
        content: '';
        display: block;
        position: absolute;
        background: url(${a1}) no-repeat center center / cover;
        width: 59px;
        height: 280px;
        top: 125px;
        left: -75px;
        @media (max-width: 992px) {
            display: none;
        }
    }
    &:nth-child(2)::after {
        content: '';
        display: block;
        position: absolute;
        background: url(${a2}) no-repeat center center / cover;
        width: 58px;
        height: 286px;
        top: 125px;
        right: -76px;
        @media (max-width: 992px) {
            display: none;
        }
    }
    &:nth-child(3)::after {
        content: '';
        display: block;
        position: absolute;
        background: url(${a3}) no-repeat center center / cover;
        width: 30px;
        height: 218px;
        top: 125px;
        left: -76px;
        @media (max-width: 992px) {
            display: none;
        }
    }
    &:nth-child(4)::after {
        content: '';
        display: block;
        position: absolute;
        background: url(${a4}) no-repeat center center / cover;
        width: 57px;
        height: 219px;
        top: 125px;
        right: -76px;
        @media (max-width: 992px) {
            display: none;
        }
    }
    &:nth-child(5)::after {
        content: '';
        display: block;
        position: absolute;
        background: url(${a5}) no-repeat center center / cover;
        width: 34px;
        height: 243px;
        top: 125px;
        left: -76px;
        @media (max-width: 992px) {
            display: none;
        }
    }
    img {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin-bottom: 20px;
        @media (max-width: 992px) {
            margin-bottom: 4px;
            width: 80px;
            height: 80px;
        }
    }
    h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-black);
        margin-bottom: 16px;
        @media (max-width: 992px) {
            font-size: 15px;
            margin-bottom: 8px;
        }
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--color-lightblack);
        @media (max-width: 992px) {
            font-size: 14px;
        }
    }
`;

const StyledStagesWrapper = styled.div`
    max-width: 749px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
    @media (max-width: 992px) {
        gap: 20px;
    }
`;

const StyledStages = styled.section`
    padding-bottom: 124px;
    @media (max-width: 992px) {
        padding-bottom: 40px;
    }
`;

export default Stages;
