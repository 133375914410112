import { Link } from 'react-scroll';
import styled from 'styled-components';

function Navigation({ burgerActive, onClick }) {
    return (
        <StyledNavigation burgerActive={burgerActive}>
            <Link onClick={onClick} to="doctors" spy={true} smooth={true} offset={50} duration={500}>
                Врачи
            </Link>
            <Link onClick={onClick} to="faq" spy={true} smooth={true} offset={50} duration={500}>
                Вопросы
            </Link>
            <Link onClick={onClick} to="requirements" spy={true} smooth={true} offset={50} duration={500}>
                Этапы лечения
            </Link>
            <Link onClick={onClick} to="whychoose" spy={true} smooth={true} offset={50} duration={500}>
                Преимущества
            </Link>
            <Link onClick={onClick} to="reviews" spy={true} smooth={true} offset={50} duration={500}>
                Отзывы
            </Link>
        </StyledNavigation>
    );
}

const StyledNavigation = styled.nav`
    max-width: 557px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 992px) {
        display: none;
    }
    ${({ burgerActive }) =>
        burgerActive &&
        `
        @media (max-width: 992px) {
        display: flex;
    }
        flex-direction: column;
        gap: 24px;
    `}
    a {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: var(--color-black);
        transition: color var(--transition);
        cursor: pointer;
        &:hover {
            color: var(--color-darkblue);
        }
    }
`;

export default Navigation;
