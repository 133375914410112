import styled from 'styled-components';

export const Title = styled.h3`
    font-weight: 700;
    font-size: 35px;
    line-height: 120%;
    text-align: center;
    color: var(--color-darkblue);
    margin: 0px auto 40px auto;
    @media (max-width: 992px) {
        font-size: 16px;
        margin-bottom: 24px;
    }
`;
