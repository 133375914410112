import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import callBg from '../../assets/call-bg.png';
import Form from '../UI/Form/Form';

function SubmitApplication() {
    return (
        <StyledSubmitApplication>
            <Container>
                <Title>Оставить заявку</Title>
                <Form inline />
            </Container>
        </StyledSubmitApplication>
    );
}

const StyledSubmitApplication = styled.section`
    padding-top: 40px;
    padding-bottom: 94px;
    background: url(${callBg}) no-repeat center center / cover;
    @media (max-width: 992px) {
        background-position: -200px;
        padding-top: 10px;
        padding-bottom: 22px;
    }
`;

export default SubmitApplication;
