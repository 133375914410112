import styled from 'styled-components';
import { ReactComponent as IndicatorIcon } from '../../../assets/tripple-arrows.svg';

function Indicator() {
    return (
        <StyledIndicator>
            <div>
                <h5>Болят суставы?</h5>
                <p>Звоните прямо сейчас!</p>
            </div>
            <IndicatorIcon />
        </StyledIndicator>
    );
}

const StyledIndicator = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
    @media (max-width: 992px) {
        display: none;
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        h5 {
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: var(--color-darkblue);
        }
        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            color: var(--color-darkblue);
        }
    }
`;

export default Indicator;
