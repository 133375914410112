import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import receptionImg from '../../assets/reception-bg.jpg';
import doctorImg from '../../assets/doctor.png';
import checkIcon from '../../assets/check.svg';

function WhyChoose() {
    return (
        <StyledWhyChoose id="whychoose">
            <Container>
                <Title>Почему нужно обращаться в клинику XXI век?</Title>
                <StyledReceptionImg width="100%" height="auto" src={receptionImg} alt="Ресепшн" />
                <StyledWhyChooseWrapper>
                    <img src={doctorImg} width="253" height="auto" alt="Доктор" />
                    <StyledWhyChooseList>
                        <li>
                            <p>
                                {' '}
                                Мы заботимся о здоровье жителей Петербурга <b>c 1999 года</b>;
                            </p>
                        </li>
                        <li>
                            <p>
                                Операции проводят врачи, имеющие опыт <b>более чем 2 500 операций</b> на коленных суставах, написавшие{' '}
                                <b>более 90 научных работ</b> по артроскопии и эндопротезированию суставов;
                            </p>
                        </li>
                        <li>
                            <p>
                                В зависимости от сложности операции и общего состояния пациент может покинуть клинику <b>через несколько часов</b>{' '}
                                после артроскопии или быть оставлен под наблюдение медицинского персонала на необходимое время;
                            </p>
                        </li>
                        <li>
                            <p>
                                Операция проводится <b>при постоянном контроле</b> врача - анестезиолога;
                            </p>
                        </li>
                        <li>
                            <p>
                                По показаниям реабилитация может начаться <b>сразу после операции</b> и затем продолжаться в домашних условиях;
                            </p>
                        </li>
                        <li>
                            <p>
                                Восстановление происходит <b>под контролем</b> оперирующего хирурга и реабилитолога.
                            </p>
                        </li>
                    </StyledWhyChooseList>
                </StyledWhyChooseWrapper>
            </Container>
        </StyledWhyChoose>
    );
}

const StyledWhyChooseList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 732px;
    padding: 28px 32px;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    @media (max-width: 992px) {
        padding: 12px;
    }
    li {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-black);
        display: flex;
        @media (max-width: 992px) {
            font-size: 15px;
        }
        &:before {
            content: '';
            margin-right: 14px;
            display: block;
            background: url(${checkIcon});
            width: 24px;
            height: 24px;
            flex: none;
            @media (max-width: 992px) {
                margin-right: 3px;
            }
        }
    }
`;

const StyledWhyChooseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        img {
            width: 189px;
        }
    }
`;

const StyledReceptionImg = styled.img`
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    margin-bottom: 30px;
    @media (max-width: 992px) {
        display: none;
    }
`;

const StyledWhyChoose = styled.section`
    padding-bottom: 64px;
    @media (max-width: 992px) {
        padding-bottom: 40px;
    }
`;

export default WhyChoose;
