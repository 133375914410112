import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { ReactComponent as VkIcon } from '../../assets/footer-vk.svg';
import { ReactComponent as VkYoutube } from '../../assets/footer-youtube.svg';

function FooterBottom() {
    return (
        <StyledFooterBottom>
            <StyledFooterBottomLogo>
                <LogoIcon />
                <p>Группа компаний Медицинский центр «XXI век» @ 2023</p>
            </StyledFooterBottomLogo>
            <StyledFooterBottomMiddle>
                <p>Стационарозамещающий операционный комплекс «XXI Век»</p>
                <a href="tel:+78123800238">+7 (812) 380 02 38</a>
            </StyledFooterBottomMiddle>
            <StyledFooterBottomSocial>
                <p>Санкт-Петербург, пр. Энгельса д. 107, к.4, стр. 1.</p>
                <div>
                    <a href="https://m.vk.com/mc21vek" target={'_blank'} rel="noreferrer">
                        <VkIcon />
                    </a>
                    <a href="https://m.youtube.com/channel/UCI0dlf27aqMX4UvZ-MbUpRQ" target={'_blank'} rel="noreferrer">
                        <VkYoutube />
                    </a>
                </div>
            </StyledFooterBottomSocial>
        </StyledFooterBottom>
    );
}

const StyledFooterBottomMiddle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #000000;
        max-width: 341px;
    }
    a {
        text-align: center;
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;
        color: #305e90;
    }
`;

const StyledFooterBottomSocial = styled.div`
    max-width: 219px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    gap: 24px;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #000000;
    align-self: flex-end;
    & > div {
        display: flex;
        gap: 16px;
    }
    @media (max-width: 992px) {
        align-self: center;
        align-items: center;
        text-align: center;
    }
`;

const StyledFooterBottomLogo = styled.div`
    max-width: 190px;
    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: #172945;
        margin-top: 16px;
        @media (max-width: 992px) {
            text-align: center;
            margin-top: 8px;
        }
    }
`;

const StyledFooterBottom = styled.div`
    padding-top: 136px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding-top: 74px;
    }
`;

export default FooterBottom;
