import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import footerBg from '../../assets/footer-bg.png';
import { Title } from '../../styles/Title.styled';
import Form from '../UI/Form/Form';
import FooterBottom from './FooterBottom';

function Footer() {
    return (
        <StyledFooter>
            <Container>
                <Title>Оставить заявку</Title>
                <Form inline />
                <FooterBottom />
            </Container>
        </StyledFooter>
    );
}

const StyledFooter = styled.section`
    padding-top: 80px;
    padding-bottom: 24px;
    background: url(${footerBg}) no-repeat center center / cover;
    min-height: 510px;
    height: 100%;
    @media (max-width: 992px) {
        padding-top: 0;
        background-position: left;
    }
`;

export default Footer;
