import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../../assets/logo.svg';

function Logo({ burgerActive }) {
    return <StyledLogoIcon burgerActive={burgerActive} />;
}

const StyledLogoIcon = styled(LogoIcon)`
    flex: none;
    width: 77px;
    height: 71px;
    @media (max-width: 992px) {
        width: 53px;
        height: 42px;
        ${({ burgerActive }) =>
            burgerActive &&
            `
            width: 77px;
             height: 71px;
            `}
    }
`;

export default Logo;
