import styled from 'styled-components';
import { ButtonPrimary } from '../../../styles/Buttons.styled';
import { ReactComponent as SuccessIcon } from '../../../assets/check-circle.svg';
import { ReactComponent as FailureIcon } from '../../../assets/failure.svg';
import { useContext } from 'react';
import { PopupValue } from '../../../context';

function Popup() {
    const { success, setOpen } = useContext(PopupValue);

    return (
        <StyledPopup>
            <StyledPopupMessage>
                {success ? <StyledSuccessIcon /> : <StyledFailureIcon />}
                <h4>{success ? 'Ваша заявка отправлена' : 'Что-то пошло не так'}</h4>
                <p>{success ? 'В ближайшее время вам перезвонят для оформления заявки' : 'Попробуйте еще раз'}</p>
                <ButtonPrimary onClick={() => setOpen(false)}>Вернуться</ButtonPrimary>
            </StyledPopupMessage>
        </StyledPopup>
    );
}

const StyledSuccessIcon = styled(SuccessIcon)`
    width: 64px;
    height: 64px;
`;
const StyledFailureIcon = styled(FailureIcon)`
    width: 64px;
    height: 64px;
`;

const StyledPopupMessage = styled.section`
    padding: 32px 24px;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    width: 100%;
    margin: 0 auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 281px;
    position: relative;
    svg {
        margin-bottom: 16px;
    }
    h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #172945;
        margin-bottom: 8px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #172945;
    }
    button {
        width: 218px;
        position: absolute;
        bottom: 24px;
    }
`;

const StyledPopup = styled.section`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.7);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Popup;
