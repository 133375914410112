import styled from 'styled-components';
import checkIcon from '../../assets/check-circle.svg';
import Form from '../UI/Form/Form';

function HeroCard() {
    return (
        <StyledHeroCard checkIcon={checkIcon}>
            <small>Давайте поговорим!</small>
            <StyledStyledHeroCardWrapper>
                <ul>
                    <h4>Мы поможем:</h4>
                    <li>Определиться с тактикой лечения</li>
                    <li>Понять дальнейшие шаги</li>
                    <li>Сориентироваться по ценам</li>
                </ul>
                <Form />
            </StyledStyledHeroCardWrapper>
        </StyledHeroCard>
    );
}

const StyledStyledHeroCardWrapper = styled.div`
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 992px) {
        padding: 10px 16px;
    }
    ul {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 6px;
        h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
            color: var(--color-black);
            margin-bottom: 3px;
            @media (max-width: 992px) {
                font-size: 15px;
            }
        }
        li {
            font-weight: 400;
            font-size: 15px;
            line-height: 130%;
            color: var(--color-lightblack);
            display: flex;
            align-items: flex-start;
            gap: 6px;
            @media (max-width: 992px) {
                font-size: 14px;
            }
            &:before {
                content: '';
                display: block;
                margin-top: 2px;
                width: 15px;
                height: 15px;
                background: url(${checkIcon}) no-repeat center center / cover;
            }
        }
    }
`;

const StyledHeroCard = styled.div`
    box-sizing: border-box;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    max-width: 290px;
    background: var(--color-white);
    small {
        background: var(--color-blue);
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: var(--color-white);
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px 8px 0px 0px;
        @media (max-width: 992px) {
            font-size: 15px;
        }
    }
`;

export default HeroCard;
