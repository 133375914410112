import { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import IconCheck from '../../assets/check.svg';

function DoctorsCard({ descr, name, rank, videoAvatar }) {
    const [readMore, setReadMore] = useState(false);
    const arrname = name.split(' ');

    return (
        <StyledDoctorsCard>
            <StyledDoctorsCardAvatar>
                {/* <video
                    src={videoAvatar}
                    width="100%"
                    height={'auto'}
                    loop="loop"
                    playsinline
                    muted="muted"
                    playing="playing"
                    autoplay="autoplay"
                ></video> */}
                <ReactPlayer
                    url={videoAvatar}
                    autoPlay={true}
                    playing={true}
                    muted={true}
                    loop={true}
                    playsinline={true}
                    width="100%"
                    height={'auto'}
                />
            </StyledDoctorsCardAvatar>

            <h4>
                {`${arrname[0]} ${arrname[1]}`} <br />
                {`${arrname[2]}`}
            </h4>
            <small>{rank}</small>
            <StyledDoctorsCardText readMore={readMore}>{descr}</StyledDoctorsCardText>
            <StyledReadMore readMore={readMore} onClick={() => setReadMore(!readMore)}>
                {!readMore ? 'Подробнее' : 'Скрыть'}
            </StyledReadMore>
        </StyledDoctorsCard>
    );
}

export const StyledReadMore = styled.button`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: ${({ readMore }) => (!readMore ? 'var(--color-darkblue)' : 'var(--color-input)')};
    margin-top: 24px;
    background: var(--color-white);
`;

const StyledDoctorsCardText = styled.div`
    position: relative;
    overflow: hidden;
    height: ${({ readMore }) => (readMore ? 'auto' : '57px')};
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: var(--color-lightblack);
        margin-bottom: 16px;
        @media (max-width: 992px) {
            font-size: 14px;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 16px;
        li {
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: var(--color-lightblack);
            @media (max-width: 992px) {
                font-size: 14px;
            }

            &:before {
                content: '';
                display: block;
                background: url(${IconCheck});
                width: 24px;
                height: 24px;
                flex: none;
            }
        }
    }
    &:after {
        content: '';
        width: 100%;
        display: ${({ readMore }) => (readMore ? 'none' : 'block')};
        height: 80px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-bottom: 10px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
    }
`;

const StyledDoctorsCardAvatar = styled.div`
    position: relative;
    z-index: 2;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 2px var(--color-darkblue);
    margin-bottom: 8px;
    overflow: hidden;
    box-shadow: var(--shadow);
    @media (max-width: 768px) {
        width: 160px;
        height: 160px;
    }

    & video {
        box-shadow: var(--shadow);
        position: absolute;
        z-index: -1;
        top: -80px;
        @media (max-width: 768px) {
            top: -61px;
        }
    }
`;

const StyledDoctorsCard = styled.div`
    position: relative;
    z-index: 2;
    background: var(--colors-white);
    box-shadow: 0px 12px 24px rgba(48, 94, 144, 0.2);
    border-radius: 15px;
    padding: 20px 40px 47px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: baseline;
    @media (max-width: 992px) {
        padding: 24px 16px 32px;
    }
    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: var(--color-darkblue);
        margin-bottom: 14px;
        @media (max-width: 992px) {
            font-size: 15px;
        }
    }
    small {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-black);
        text-align: left;
        width: 100%;
        margin-bottom: 15px;
        @media (max-width: 992px) {
            font-size: 15px;
        }
    }
`;

export default DoctorsCard;
