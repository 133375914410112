import styled from 'styled-components';

function HeaderButtonCall({ burgerActive }) {
    return (
        <StyledHeaderButtonCall burgerActive={burgerActive}>
            <a href="tel:88123800238">8 812 380-02-38 </a>
            <StyledHeaderButton burgerActive={burgerActive}>Обратный звонок</StyledHeaderButton>
        </StyledHeaderButtonCall>
    );
}

const StyledHeaderButton = styled.button`
    filter: drop-shadow(1px 1px 42px rgba(88, 147, 217, 0.63));
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: var(--color-white);
    padding: 7px 12px;
    border-radius: var(--radius-second);
    background: var(--color-blue);
    ${({ burgerActive }) =>
        burgerActive &&
        `
        width: 218px;
        padding: 10px 12px;
    `}
`;

const StyledHeaderButtonCall = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @media (max-width: 992px) {
        display: none;
    }
    ${({ burgerActive }) =>
        burgerActive &&
        `
        @media (max-width: 992px) {
        display: flex;
    }
        width: 218px;
        padding: 10px 12px;
    `}
    a {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: var(--color-darkblue);
    }
`;

export default HeaderButtonCall;
