import styled from 'styled-components';
import { ReactComponent as BurgerIcon } from '../../../assets/burger.svg';
import { ReactComponent as XIcon } from '../../../assets/x.svg';

function Burger({ burgerActive, onClick }) {
    return (
        <StyledBurger burgerActive={burgerActive} onClick={onClick}>
            {burgerActive ? <XIcon /> : <BurgerIcon />}
        </StyledBurger>
    );
}

const StyledBurger = styled.button`
    display: none;
    height: 24px;
    width: 24px;
    @media (max-width: 992px) {
        display: block;
    }

    ${({ burgerActive }) =>
        burgerActive &&
        `
        position: absolute;
        top: 0px;
        right: 0px;
    `}
`;

export default Burger;
