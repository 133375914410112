import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import { Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { StyledSwiper } from '../Doctors/Doctors';
import ReviewsCard from './ReviewsCard';

const reviewsList = [
    {
        author: 'Егор Д.',
        comment: (
            <p>
                Прекрасная клиника. Сделали операцию на колено, все качественно. Отдельная благодарность Воробьёвой Анне по организации и
                сопровождению по всем вопросам.
            </p>
        ),
    },
    {
        author: 'Иван К.',
        comment: (
            <>
                <p>
                    6 декабря 2022 года сделал в этой клинике операцию, за которую многие клиники Санкт-Петербурга либо не брались, либо цена была
                    слишком неподъемной. Проанализировав множество клиник, пришел к выводу: цены в клинике «21 век» приемлемые, не самые дешевые, но и
                    не самые высокие. Приятно удивил профессионализм специалистов и персональный подход конкретно к моей травме. Качество на высшем
                    уровне, полностью оправдывает вложенные средства.
                </p>
                <p>
                    Отдельную благодарность хотел бы сказать своему лечащему врачу Мехтиханову Тимуру Вердихановичу – качественно и быстро
                    прооперировал, на всем пути к операции всегда был на связи и осуществлял постоянную поддержку и консультацию, кульминацией явилась
                    операция, которая прошла успешно и с минимальными болезненными ощущениями. Также стоит отметить работу менеджера Воробьевой Анны
                    Вячеславовны – всячески помогала и координировала в организационных вопросах, помощь в сдаче всех необходимых анализов и
                    консультаций, помощь 24/7.
                </p>
                <p>
                    Выводы: работой клиники более чем доволен и всем рекомендую, не разочаруетесь. Сильная и профессиональная команда клиники приятно
                    вас удивит, и вы получите наилучшее качество по максимально выгодной цене. Всем спасибо.
                </p>
            </>
        ),
    },
    {
        author: 'Владимир Г.',
        comment: (
            <>
                <p>
                    Здравствуйте! Всем доволен, договаривался дистанционно, прилетел с Дальнего Востока на операцию, которая была 5 декабря: резекция
                    мениска 2 категории сложности с абразивной хондропластикой.
                </p>
                <p>
                    Всё прошло на высшем уровне, оперировал опытный травматолог-ортопед Шулепов Дмитрий Александрович с 18-летним стажем в этой сфере.
                    Назначено грамотное медикаментозное лечение и приоритетные рекомендации.
                </p>
                <p>Большое спасибо Воробьёвой Анне Вячеславовне за помощь в организации и решении всех вопросов.</p>
                <p>Рекомендую, останетесь довольны!</p>
            </>
        ),
    },
    {
        author: 'Дмитрий С.',
        comment: (
            <>
                <p>
                    Хорошая клиника, отзывчивый персонал. Все выполнено в срок, как и было оговорено. Дата и время операции были согласованы, и все
                    нюансы оговорены заранее. Доктор (Шулепов Д. А.) адекватный, грамотный, все объясняет и рассказывает на доступном языке.
                </p>
            </>
        ),
    },
    {
        author: 'Владимир Г.',
        comment: (
            <>
                <p>
                    Здравствуйте! Всем доволен, договаривался дистанционно, прилетел с Дальнего Востока на операцию, которая была 5 декабря: резекция
                    мениска 2 категории сложности с абразивной хондропластикой.
                </p>
                <p>
                    Всё прошло на высшем уровне, оперировал опытный травматолог-ортопед Шулепов Дмитрий Александрович с 18-летним стажем в этой сфере.
                    Назначено грамотное медикаментозное лечение и приоритетные рекомендации.
                </p>
                <p>Большое спасибо Воробьёвой Анне Вячеславовне за помощь в организации и решении всех вопросов.</p>
                <p>Рекомендую, останетесь довольны!</p>
            </>
        ),
    },
];

function Reviews() {
    return (
        <StyledReviews id="reviews">
            <Container>
                <Title>Отзывы пациентов после операции</Title>
                <StyledSwiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    watchSlidesVisibility={false}
                    watchOverflow={true}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: '3',
                            // centeredSlides: true,
                        },
                    }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {reviewsList.map((item, index) => (
                        <SwiperSlide key={index}>
                            <ReviewsCard {...item} />
                        </SwiperSlide>
                    ))}
                </StyledSwiper>
            </Container>
        </StyledReviews>
    );
}

const StyledReviews = styled.section`
    padding-bottom: 32px;
`;

export default Reviews;
