import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import Navigation from '../UI/Navigation/Navigation';
import HeaderButtonCall from './HeaderButtonCall';
import Indicator from '../UI/Indicator/Indicator';
import Burger from '../UI/Burger/Burger';
import Logo from '../UI/Logo/Logo';
import { useEffect, useState } from 'react';
import { ReactComponent as VkIcon } from '../../assets/footer-vk.svg';
import { ReactComponent as InstaIcon } from '../../assets/footer-youtube.svg';
import useWindowWidth from 'react-hook-use-window-width';

function Header() {
    const [burgerActive, setBurgerActive] = useState(false);
    const width = useWindowWidth();

    useEffect(() => {
        if (burgerActive && width > 992) {
            setBurgerActive(false);
        }
    }, [width]);

    useEffect(() => {
        if (burgerActive) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = 'auto';
        }
    }, [burgerActive]);

    return (
        <StyledHeader burgerActive={burgerActive}>
            <Container>
                <StyledHeaderWrapper burgerActive={burgerActive}>
                    <Logo burgerActive={burgerActive} />
                    <Navigation burgerActive={burgerActive} onClick={() => setBurgerActive(false)} />
                    <StyledHeaderRight>
                        <Indicator />
                        <HeaderButtonCall burgerActive={burgerActive} />
                    </StyledHeaderRight>
                    <Burger burgerActive={burgerActive} onClick={() => setBurgerActive(!burgerActive)} />
                    {burgerActive && (
                        <StyledHeaderSocials>
                            <a href="https://m.vk.com/mc21vek" target={'_blank'} rel="noreferrer">
                                <VkIcon />
                            </a>
                            <a href="https://m.youtube.com/channel/UCI0dlf27aqMX4UvZ-MbUpRQ" target={'_blank'} rel="noreferrer">
                                <InstaIcon />
                            </a>
                        </StyledHeaderSocials>
                    )}
                </StyledHeaderWrapper>
            </Container>
        </StyledHeader>
    );
}

const StyledHeaderSocials = styled.div`
    display: flex;
    gap: 24px;
`;

const StyledHeaderWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ burgerActive }) =>
        burgerActive &&
        `
        height: 100vh;
        overflow: hidden;
        flex-direction: column;
        padding-top: 17px;
        justify-content: flex-start;
        gap: 32px;
    `}
`;

const StyledHeaderRight = styled.div`
    display: flex;
    flex: none;
    gap: 31px;
`;

const StyledHeader = styled.header`
    position: relative;
    padding-top: 25px;
    padding-bottom: 18px;
    box-shadow: 0px 12px 24px rgba(51, 51, 51, 0.35);
    z-index: 2;
    @media (max-width: 992px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
`;

export default Header;
