import React from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import chevronDown from '../../assets/chevrone.svg';
import { Title } from '../../styles/Title.styled';

const items = [
    {
        header: 'Что такое артроскопия и какие бывают показания?',
        content: (
            <iframe
                width="1280"
                height="854"
                src="https://www.youtube.com/embed/V9R_3usSwpk"
                title="Что такое артроскопия и какие бывают показания?"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        ),
    },
    {
        header: 'Для чего нужна предоперационная подготовка?',
        content: (
            <iframe
                width="530"
                height="942"
                src="https://www.youtube.com/embed/QJWx2jqDHYI"
                title="Для чего нужна предоперационная подготовка?"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        ),
    },
    {
        header: 'Как проходит операция?',
        content: (
            <iframe
                width="530"
                height="942"
                src="https://www.youtube.com/embed/yxOourZmZ6Q"
                title="Как проходит операция?"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        ),
    },
    {
        header: 'Для чего нужна реабилитация?',
        content: (
            <iframe
                width="530"
                height="942"
                src="https://www.youtube.com/embed/zUbXN9FWo4I"
                title="Для чего нужна реабилитация?"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
        ),
    },
];

const ItemWithChevron = ({ header, ...rest }) => (
    <AccordionItem
        {...rest}
        header={
            <>
                {header}
                <StyledChevron className="chevron-down" src={chevronDown} alt="Chevron Down" />
            </>
        }
    />
);

const StyledChevron = styled.img`
    @media (max-width: 992px) {
        width: 21px;
        height: 11px;
    }
`;

function FAQ() {
    return (
        <StyledFAQ id="faq">
            <Container>
                <Title>Ответы на вопросы</Title>
                <StyledFAQWrapper>
                    <StyledAccordion transition transitionTimeout={200}>
                        {items.map(({ header, content }, i) => (
                            <StyledAccordionItem header={header} key={i}>
                                {content}
                            </StyledAccordionItem>
                        ))}
                    </StyledAccordion>
                </StyledFAQWrapper>
            </Container>
        </StyledFAQ>
    );
}

const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 992px) {
        gap: 16px;
    }
`;

const StyledFAQWrapper = styled.div`
    max-width: 920px;
    margin: 0 auto;
`;

const StyledAccordionItem = styled(ItemWithChevron)`
    background: var(--color-white);
    box-shadow: 0px 12px 24px rgba(48, 94, 144, 0.2);
    border-radius: 8px;

    .szh-accordion__item {
        &-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-black);
            padding: 20px 32px;
            background-color: transparent;
            border: none;
            text-align: left;
            @media (max-width: 992px) {
                padding: 8px 10px;
                font-size: 14px;
            }
        }

        &-content {
            transition: height 0.2s ease-in-out;
            padding: 20px 32px;
            @media (max-width: 992px) {
                padding: 8px 16px 16px 16px;
            }
        }

        &-panel {
            position: relative;
            padding-bottom: 56.25%;
            @media (max-width: 768px) {
                padding-bottom: 0;
            }
        }
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
            position: static;
            height: calc(100vh - 64px);
        }
    }

    .chevron-down {
        margin-left: auto;
        transition: transform 0.2s ease-in-out;
    }

    &.szh-accordion__item--expanded {
        .szh-accordion__item-btn {
        }
        .chevron-down {
            transform: rotate(180deg);
        }
    }
`;

const StyledFAQ = styled.section`
    padding-bottom: 64px;
    @media (max-width: 992px) {
        padding-bottom: 30px;
    }
`;

export default FAQ;
