import styled from 'styled-components';
import { Container } from '../../styles/Container.styled';
import { Title } from '../../styles/Title.styled';
import ReqImg from '../../assets/requirements.png';

function Requirements() {
    return (
        <StyledRequirements id="requirements">
            <Container>
                <Title>В каких случаях нужна консультация травматолога?</Title>
                <StyledRequirementsWrapper>
                    <StyledRequirementsCard>
                        <small>Симптомы</small>
                        <ul>
                            <li>Боль в суставах рук или ног, коленях или локтях</li>
                            <li>Чувство "заклинивания" или другие неприятные механические ощущения</li>
                            <li>Появление боли при нагрузке(ходьбе, беге, занятиях спортом)</li>
                            <li>Вывих надколенника, нестабильность сустава</li>
                        </ul>
                    </StyledRequirementsCard>

                    <StyledRequirementImg src={ReqImg} width="282" height="auto" alt="Колено" />

                    <StyledRequirementsCard>
                        <small>Медицинские показания</small>
                        <ul>
                            <li>Травма и подозрение на повторный разрыв после предыдущих операций</li>
                            <li>Наличие травм в прошлом, например застарелых травм мениска</li>
                            <li>Обнаружение при МРТ обследовании повреждения менисков, связок</li>
                            <li>Направление других врачей и специалистов</li>
                        </ul>
                    </StyledRequirementsCard>
                </StyledRequirementsWrapper>
            </Container>
        </StyledRequirements>
    );
}

const StyledRequirementImg = styled.img`
    max-width: 282px;
    min-width: 203px;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        width: 203px;
    }
`;

const StyledRequirementsCard = styled.div`
    box-sizing: border-box;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    max-width: 350px;
    min-width: 290px;
    width: 100%;
    background: var(--color-white);
    small {
        background: var(--color-blue);
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: var(--color-white);
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px 8px 0px 0px;
        @media (max-width: 992px) {
            font-size: 15px;
        }
    }
    ul {
        padding: 15px 24px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        @media (max-width: 992px) {
            gap: 9px;
            padding: 9px 15px;
        }
        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: var(--color-black);
            @media (max-width: 992px) {
                font-size: 15px;
            }
            &:not(:last-child) {
                border-bottom: solid 1px #d1e6ff;
                padding-bottom: 15px;
                @media (max-width: 992px) {
                    padding-bottom: 9px;
                }
            }
        }
    }
`;

const StyledRequirementsWrapper = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledRequirements = styled.section`
    padding-top: 64px;
    padding-bottom: 64px;
    @media (max-width: 992px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

export default Requirements;
