import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import starIcon from '../../assets/star.svg';
import { StyledReadMore } from '../Doctors/DoctorsCard';

function ReviewsCard({ author, comment }) {
    const [readMore, setReadMore] = useState(false);
    const [showReadMore, setShowReadMore] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        checkHeight();
    }, [ref]);

    const checkHeight = () => {
        const element = ref.current.childNodes;
        let resHeight = 0;
        element.forEach((item) => (resHeight += item.offsetHeight + 16));
        if (resHeight < 310) {
            setShowReadMore(false);
        } else {
            setShowReadMore(true);
        }
    };

    return (
        <StyledReviewsCard>
            <h4>{author}</h4>
            <StyledReviewsStars>
                <img src={starIcon} alt="Звезда" />
                <img src={starIcon} alt="Звезда" />
                <img src={starIcon} alt="Звезда" />
                <img src={starIcon} alt="Звезда" />
                <img src={starIcon} alt="Звезда" />
            </StyledReviewsStars>
            <StyledReviewsCardText readMore={readMore} ref={ref}>
                {comment}
            </StyledReviewsCardText>
            {showReadMore && (
                <StyledReadMore readMore={readMore} onClick={() => setReadMore(!readMore)}>
                    {!readMore ? 'Развернуть' : 'Скрыть'}
                </StyledReadMore>
            )}
        </StyledReviewsCard>
    );
}

const StyledReviewsCardText = styled.div`
    position: relative;
    overflow: hidden;
    height: ${({ readMore }) => (readMore ? 'auto' : '310px')};
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: var(--color-lightblack);
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &:after {
        content: '';
        width: 100%;
        display: ${({ readMore }) => (readMore ? 'none' : 'block')};
        height: 100px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 10px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
    }
`;

const StyledReviewsStars = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
`;

const StyledReviewsCard = styled.article`
    padding: 24px 16px;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 460px;
    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-black);
        margin-bottom: 10px;
    }
`;

export default ReviewsCard;
