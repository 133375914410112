import styled from 'styled-components';

export const ButtonPrimary = styled.button`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--color-white);
    padding: 10px 0;
    background: var(--color-blue);
    box-shadow: var(--shadow);
    border-radius: var(--radius-second);
`;
