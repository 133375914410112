import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import styled from 'styled-components';
import { ButtonPrimary } from '../../../styles/Buttons.styled';
import axios from 'axios';
import { PopupValue } from '../../../context';
import ym from 'react-yandex-metrika';

function Form({ inline }) {
    const { setOpen, setSuccess } = useContext(PopupValue);

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    const onSubmit = (data) => {
        const formatData = {
            form_text_421: data.name,
            form_text_422: '+' + data.phone.replace(/\D/g, ''),
            WEB_FORM_ID: 25,
            web_form_apply: 'Y',
        };
        const formData = new FormData();

        // добавляем данные из объекта data в formData
        Object.keys(formatData).forEach((key) => {
            formData.append(key, formatData[key]);
        });

        console.log(formData);

        axios
            .post(`/form.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(function () {
                setSuccess(true);
                setOpen(true);
                ym('reachGoal', 'Отправить заявку');
            })
            .catch(function (error) {
                console.log(error);
                setSuccess(false);
                setOpen(true);
            });
        reset();
    };

    return (
        <StyledForm inline={inline} onSubmit={handleSubmit(onSubmit)}>
            <label style={errors.name?.message && { borderColor: 'red' }}>
                <input
                    placeholder={'Имя'}
                    {...register('name', {
                        required: 'Поле обязательно для заполнений.',
                    })}
                />
            </label>
            <Controller
                name="phone"
                control={control}
                rules={{
                    required: 'Поле обязательно для заполнений.',
                    minLength: {
                        value: 18,
                        message: 'Ведите корректный номер телефона',
                    },
                }}
                render={({ field }) => (
                    <label style={errors.phone?.message && { borderColor: 'red' }}>
                        <IMaskInput {...field} mask="+{7} (000) 000-00-00" placeholder={'Телефон'} />
                    </label>
                )}
            />
            <ButtonPrimary>Записаться</ButtonPrimary>
        </StyledForm>
    );
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    ${({ inline }) =>
        inline &&
        `
        flex-direction: row;
        max-width: 715px;
        margin: 0 auto;
        button{
            width: 100%;
            max-width: 225px;
            @media(max-width: 992px) {
            max-width: none;
        }
        }
        @media(max-width: 992px) {
            flex-direction: column;
            max-width: 290px;
        }

    `}
    label {
        padding: 8px 16px 6px 16px;
        border-radius: 8px;
        border: 1px solid #d7d7d7;
        background: var(--color-white);
    }
    input {
        width: 100%;
        background: var(--color-white);
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        color: #75758b;
        &::placeholder {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            color: #75758b;
        }
    }
`;

export default Form;
